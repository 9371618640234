@font-face {
  font-family: 'mtx';
  src:
    url('./mtx.ttf?cc9ku4') format('truetype'),
    url('./mtx.woff?cc9ku4') format('woff'),
    url('./mtx.svg?cc9ku4#mtx') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.mtx-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'mtx' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mtx-icon-sd-quality:before {
  content: "\e900";
}
.mtx-icon-hd-quality .path1:before {
  content: "\e901";
  color: rgb(0, 0, 0);
}
.mtx-icon-hd-quality .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.mtx-icon-hd-quality .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.mtx-icon-whistle:before {
  content: "\e904";
}
.mtx-icon-score-details:before {
  content: "\e905";
}
.mtx-icon-driver-scoring:before {
  content: "\e906";
}
.mtx-icon-siren:before {
  content: "\e907";
}
.mtx-icon-add-user:before {
  content: "\e908";
}
.mtx-icon-add-vehicle:before {
  content: "\e909";
}
.mtx-icon-alarm-report:before {
  content: "\e90a";
}
.mtx-icon-configure:before {
  content: "\e90b";
}
.mtx-icon-directions:before {
  content: "\e90c";
}
.mtx-icon-driver-behavior:before {
  content: "\e90d";
}
.mtx-icon-event-history:before {
  content: "\e90e";
}
.mtx-icon-fixed-route:before {
  content: "\e90f";
}
.mtx-icon-follow:before {
  content: "\e910";
}
.mtx-icon-geo-fencing:before {
  content: "\e911";
}
.mtx-icon-home:before {
  content: "\e912";
}
.mtx-icon-info:before {
  content: "\e913";
}
.mtx-icon-live-eye:before {
  content: "\e914";
}
.mtx-icon-live-tracking:before {
  content: "\e915";
}
.mtx-icon-maintenance:before {
  content: "\e916";
}
.mtx-icon-maintenance-nav:before {
  content: "\e917";
}
.mtx-icon-my-fleet:before {
  content: "\e918";
}
.mtx-icon-notes:before {
  content: "\e919";
}
.mtx-icon-overspeed-sav:before {
  content: "\e91a";
}
.mtx-icon-pdf:before {
  content: "\e91b";
}
.mtx-icon-police-badge:before {
  content: "\e91c";
}
.mtx-icon-rfid:before {
  content: "\e91d";
}
.mtx-icon-route-rewind:before {
  content: "\e91e";
}
.mtx-icon-setup:before {
  content: "\e91f";
}
.mtx-icon-sim-card:before {
  content: "\e920";
}
.mtx-icon-system-health:before {
  content: "\e921";
}
.mtx-icon-tech-support:before {
  content: "\e922";
}
.mtx-icon-tracking:before {
  content: "\e923";
}
.mtx-icon-wireless:before {
  content: "\e924";
}
.mtx-icon-wireless-x:before {
  content: "\e925";
}
.mtx-icon-reports:before {
  content: "\e926";
}
.mtx-icon-route-management:before {
  content: "\e927";
}
.mtx-icon-target:before {
  content: "\e9b3";
}
.mtx-icon-stop-sign:before {
  content: "\e9b4";
}

/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.css";
@import "nouislider/dist/nouislider.min.css";
@import "leaflet/dist/leaflet.css";
@import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
@import "leaflet-control-geocoder/dist/Control.Geocoder.css";
@import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
@import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
@import "./assets/fonts/mototrax-icon-fontpack/style.css";
@import "leaflet.markercluster/dist/MarkerCluster.css";
@import "leaflet.markercluster/dist/MarkerCluster.Default.css";

html, body {
	height: 100%;
    min-height: 100%;
    font-size: 14px;
    font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
    margin: 0;
    padding: 0;
}

.wrapper {
  min-height: calc(100% - 115px - 1rem);
  height: auto;
  /* Negative indent footer by its height */
  margin: 0 auto -85px;
  /* Pad bottom by footer height */
  padding: 0 0 85px;
}

.push {
    height: 1;
}

.gap-1 {
  gap: 1rem;
}
.gap-2 {
  gap: 2rem;
}
.gap-3 {
  gap: 3rem;
}
.gap-4 {
  gap: 4rem;
}
.gap-5 {
  gap: 5rem;
}

.row.justify-content-center > *,
.row.justify-content-around > *,
.row.justify-content-between > *,
.row.justify-content-evenly > *
 {
  width: initial;
}

/* bootstrap pager overrides */

.page-link {
  font-size: 14px;
  line-height: 14px;
  border-right: 1px solid #3c3c3e;
  background: none;
  border: none;
  border-right: 2px solid #3c3c3e;
  font-weight: bold;
}

.page-item:last-of-type .page-link{
  border: none;
}

.page-item.disabled .page-link {
  background: none;
}

.page-item.active .page-link {
  background: none;
  color: #3c3c3e;
   border: none;
  border-right: 2px solid #3c3c3e;
}

/** FORM STYLE OVERRIDES **/
/* VALIDATION COLORS */

input.ng-touched.ng-invalid {
  background: rgba(187,0,0, 0.3);
  border-bottom: 3px solid #bb0000;
}

form.ng-submitted input.ng-invalid, form.ng-submitted select.ng-invalid, form.ng-submitted textarea.ng-invalid {
  background: rgba(187,0,0, 0.3);
  border-bottom: 3px solid #bb0000;
}

form.ng-submitted input.ng-valid, form.ng-submitted select.ng-valid, form.ng-submitted textarea.ng-valid,
  input.ng-valid.ng-dirty , select.ng-valid.ng-dirty, textarea.ng-valid.ng-dirty {
  border-bottom: 3px solid #5c913c !important;
}

// overwrite bs styling for this selector
.table>:not(caption)>*>* {
  background-color: inherit;
  border-bottom-width: inherit;
}

.ngb-modal-blur {
  background: rgb(255, 255, 255, 20%);
  backdrop-filter: blur(5px);
}
